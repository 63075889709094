import cx from 'classnames'
import { omit } from 'lodash'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, Redirect, useLocation } from 'react-router-dom'

import { REDESIGN_ROOT_PATH } from '../../../helpers'
import { useBeamSelector } from '../../../hooks'
import { logIn } from '../../../redux/thunks/authThunks'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamLogo } from '../../../stories/BeamLogo'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamInputChangeEvent } from '../../../stories/BeamTextfield/BeamTextfield'
import { isAuthenticated } from '../../../utils/helpers/isAuthenticated'
import { TUser } from '../../../utils/types'
import { APIError } from '../APIError'
import { BeamSEO } from '../BeamSEO'
import { ShopifyRoutesDispatcher } from '../shopify-public-app/ShopifyRoutesDispatcher'
import { OnboardingStepParamValues } from '../shopify-public-app/types'
import $$ from './login-page.module.css'

export const LoginPage = () => {
  const dispatch = useDispatch()
  const { user, loadingStates }: { user: TUser | undefined; loadingStates: any } = useBeamSelector(
    (state: any) => ({
      user: state.user,
      loadingStates: state.loadingStates,
    })
  )
  const initialCredentials = { email: '', password: '' }
  const isAuthenticatedUser = isAuthenticated(user)

  // Uncomment the following for a more convenient dev experience.
  // if (process.env.NODE_ENV === 'development') {
  //   initialCredentials = { email: 'someemail@fromdata.com', password: 'abc123' }
  // }

  const [credentials, setCredentials] = useState(initialCredentials)
  const [firstLoad, setFirstLoad] = useState<boolean>(true)

  const captureValue: BeamInputChangeEvent = e => {
    const target = e.target
    if (target?.name) {
      const newCredentials = {
        ...credentials,
        [target.name]: target.value,
      }
      setCredentials(newCredentials)
    }
  }

  const authenticate = (e: { preventDefault: () => void } | undefined) => {
    e?.preventDefault()
    dispatch(logIn(credentials, true))
    setFirstLoad(false)
  }

  if (isAuthenticatedUser) {
    return <LoginRedirector user={user} />
  }

  if (user?.twoFactorToken && user?.id) {
    return (
      <Redirect to={`${REDESIGN_ROOT_PATH}/login/two-factor/${user.twoFactorToken}/${user.id}`} />
    )
  }

  return (
    <div className={`${$$.loginPage} w-full h-full beam-bg--gradient-coral`}>
      <BeamSEO title={`Login`} />
      <div className="flex items-center justify-center h-screen min-h-full px-4 py-12 sm:px-6 lg:px-8">
        {loadingStates?.user?.loading ? (
          <div>Logging you in...</div>
        ) : (
          <div
            className={cx(
              $$.loginPageForm,
              'flex flex-col items-center justify-center flex-grow w-full h-full max-w-2xl p-2 space-y-8 text-center desktop:p-28 desktop:h-auto'
            )}>
            <BeamLogo alt="Beam Impact Partner Portal Login" />

            <form onSubmit={authenticate} className="space-y-6">
              <BeamTextfield
                id="email"
                name="email"
                value={credentials.email}
                placeholder="Email Address"
                onChange={captureValue}
                autofocus
                className="w-full"
                isPlainInput
              />
              <BeamTextfield
                id="password"
                name="password"
                value={credentials.password}
                placeholder="Password"
                onChange={captureValue}
                type="password"
                autocomplete="off"
                className="w-full"
                isPlainInput
              />
              <BeamButton label="Log In" variant="elevated" type="submit" />
              <input
                name="This log in button exists to allow for the enter key to be pressed"
                type="submit"
                className="hidden"
              />
            </form>
            <Link to={`${REDESIGN_ROOT_PATH}/forgot-password`}>Forgot Password?</Link>
            <div>
              {!firstLoad &&
                loadingStates.user &&
                loadingStates.user.error &&
                (loadingStates.user.error?.response?.data?.error ? (
                  <div style={{ color: 'var(--beam-color--error)' }}>
                    {loadingStates.user.error.response.data.error}
                  </div>
                ) : (
                  <APIError error={loadingStates.user.error} />
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

/**
 * Handles redirecting to the appropriate pages based on custom logic provided for specific flows.
 * By default, redirects to the last page the user tried to visit before being asked to log in.
 */
function LoginRedirector(props: { user: TUser | undefined }) {
  const location: any = useLocation()
  const pathname = location.state ? location.state.from.pathname : `${REDESIGN_ROOT_PATH}/login`
  const isLoginPathname = [
    `${REDESIGN_ROOT_PATH}/login`,
    `${REDESIGN_ROOT_PATH}/logout`,
    `/logout`,
  ].includes(pathname)
  const isPartnerUser = !!props.user?.partner?.name

  const prevPath = !isLoginPathname
    ? pathname
    : isPartnerUser
    ? `${REDESIGN_ROOT_PATH}/overview`
    : '/'

  if (prevPath.includes('/shopify-public-app/install')) {
    const routerStateWithoutFrom = omit(location.state, ['from'])
    const onboardingStep: OnboardingStepParamValues | undefined = location.state.onboardingStep
    return (
      <ShopifyRoutesDispatcher
        locationState={routerStateWithoutFrom}
        onboardingStep={onboardingStep}
      />
    )
  }

  return <Redirect to={{ pathname: prevPath, search: location.search }} />
}
