import './beam-button.css'

import SlButtonElement from '@shoelace-style/shoelace/dist/components/button/button'
import { SlButton } from '@shoelace-style/shoelace/dist/react'
import cx from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import { BeamShoelaceProps } from '../interface'

interface ButtonProps extends BeamShoelaceProps, HTMLAttributes<any> {
  /**
   * Text for button (alternative to passing child element}
   */
  label?: string | React.ReactNode
  /**
   * Text for button (can also use label prop)
   */
  children?: string | React.ReactNode
  /**
   * Type of button
   */
  type?: 'button' | 'submit'
  /**
   * Style of button
   */
  variant?:
    | 'basic'
    | 'basic_blue'
    | 'neutral'
    | 'mini'
    | 'elevated'
    | 'white'
    | 'text'
    | 'input'
    | 'flat_white'
    | 'emphasis_white'
    | 'borderless_white'
  /**
   * Disable the button?
   */
  disabled?: boolean
  /**
   * Size of button
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Whether the button should display as a block or inline element.
   * Currently defaults to true, but will default to false in the future.
   * Can also be set via Tailwind `block` / `inline-block` class.
   */
  block?: boolean
  /**
   * Optional click handler
   */
  onClick?: () => void
  /**
   * The font weight for the button label. Overrides the default 900 (black) weight.
   */
  fontWeight?: 'normal' | 'medium'
}

/**
 * Primary button component
 */
export const BeamButton = forwardRef<SlButtonElement, ButtonProps>(function BeamButton(
  {
    children,
    label,
    onClick,
    type = 'button',
    variant = 'basic',
    disabled = false,
    size = 'medium',
    block = true,
    ...props
  },
  ref
) {
  let variantClassname

  switch (variant) {
    case 'basic':
      variantClassname = 'beam--button--basic'
      break
    case 'basic_blue':
      variantClassname = 'beam--buttom--basic-blue'
      break
    case 'neutral':
      variantClassname = 'beam--button--neutral'
      break
    case 'mini':
      // Note: will be deprecated in favor of "size" prop
      variantClassname = 'beam--button--mini'
      break
    case 'elevated':
      variantClassname = 'beam--button--elevated'
      break
    case 'white':
      variantClassname = 'beam--button--white'
      break
    case 'text':
      variantClassname = 'beam--button--text'
      break
    case 'input':
      variantClassname = 'beam--button--input'
      break
    case 'flat_white':
      variantClassname = 'beam--button--flat-white'
      break
    case 'emphasis_white':
      variantClassname = 'beam--button--emphasis-white'
      break
    case 'borderless_white':
      variantClassname = 'beam--button--borderless-white'
      break
  }

  const buttonSize = variant === 'mini' ? 'small' : size

  return (
    <SlButton
      {...props}
      ref={ref}
      type={type}
      slot={props.slot}
      variant={variant === 'text' ? 'text' : undefined}
      className={cx(
        'beam--button',
        variantClassname,
        {
          ['beam--button--block']: block,
          [`font-${props.fontWeight}`]: !!props.fontWeight,
        },
        props.className
      )}
      size={buttonSize}
      disabled={disabled}
      onClick={onClick}>
      {label || children || null}
    </SlButton>
  )
})
