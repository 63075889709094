import { useHistory } from 'react-router'

import { REDESIGN_ROOT_PATH } from '../../../../helpers'
import { BeamButton } from '../../../../stories/BeamButton'

export const GoToOverviewButton = () => {
  const routerHistory = useHistory()
  return (
    <BeamButton
      className={'w-2/3 mt-8'}
      onClick={() => routerHistory.push(`${REDESIGN_ROOT_PATH}/overview`)}
      variant={'elevated'}>
      Go to your Overview
    </BeamButton>
  )
}
