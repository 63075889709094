import { inRange } from 'lodash'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { getV2ApiUrl } from '../../../api/auth'
import { REDESIGN_ROOT_PATH } from '../../../helpers'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamLoadingIndicator } from '../../../stories/BeamLoadingIndicator'
import { axiosRequest } from '../../../utils/axiosRequest'
import { GoToOverviewButton } from './components/GoToOverviewButton'
import { ShopifyContentWrapper } from './ShopifyContentWrapper'
import { ShopifyReferenceError } from './ShopifyReferenceError'
import { LocationShopifyStates, OnboardingStepParamValues } from './types'

const ErrorView = ({
  variant,
  errorMessage,
}: {
  variant: 'connect_error' | 'upgrade_request_error'
  errorMessage?: string
}) => {
  return (
    <ShopifyContentWrapper>
      <h2 className={'text-center font-black'}>
        There was an error processing your{' '}
        {variant === 'connect_error' ? 'app connection' : 'upgrade request'}.
        <br />
        Please try again or contact support.
      </h2>

      {!!errorMessage && <ShopifyReferenceError errorMessage={errorMessage} classNames={'mt-4'} />}

      <GoToOverviewButton />
      <div className={errorMessage && 'mt-5'}>
        <Link to={`${REDESIGN_ROOT_PATH}/contact-support`}>Contact Support</Link>
      </div>
    </ShopifyContentWrapper>
  )
}

/**
 * This page is reached if onboarding_step is:
 * - private_app_upgrade_awaiting_approval
 * - private_app_upgrade_begin
 */
export const ShopifyAppUpgradePage = () => {
  const location = useLocation<LocationShopifyStates>()
  const onboardingStep: OnboardingStepParamValues | undefined = location?.state?.onboardingStep
  const [upgradeRequestStatus, setUpgradeRequestStatus] = useState({
    loading: false,
    error: '',
    completed: false,
  })

  async function handleSubmit() {
    setUpgradeRequestStatus(prev => ({ ...prev, loading: true }))

    try {
      const response = await axiosRequest(
        'POST',
        `${getV2ApiUrl()}/shopify-public-app/request-public-app-upgrade`
      )
      if (inRange(response.status, 200, 300)) {
        setUpgradeRequestStatus(prev => ({ ...prev, completed: true, loading: false }))
      }
    } catch (error: any) {
      console.error(error)
      setUpgradeRequestStatus(prev => ({
        ...prev,
        completed: true,
        loading: false,
        error: error.message,
      }))
    }
  }

  if (
    onboardingStep === OnboardingStepParamValues.PRIVATE_APP_UPGRADE_AWAITING_APPROVAL ||
    (upgradeRequestStatus.completed && !upgradeRequestStatus.error)
  ) {
    return (
      <ShopifyContentWrapper>
        <h2 className={'text-center'}>Upgrade request received! Beam will be in touch soon.</h2>
        <GoToOverviewButton />
      </ShopifyContentWrapper>
    )
  }

  if (upgradeRequestStatus.completed && upgradeRequestStatus.error) {
    return <ErrorView variant={'upgrade_request_error'} errorMessage={upgradeRequestStatus.error} />
  }

  return upgradeRequestStatus.loading ? (
    <ShopifyContentWrapper>
      <BeamLoadingIndicator />
    </ShopifyContentWrapper>
  ) : (
    <ShopifyContentWrapper>
      <h2 className={'pb-4'}>Would you like to upgrade to the Shopify public app?</h2>

      <BeamButton variant={'elevated'} className={'w-2/3'} onClick={handleSubmit}>
        Upgrade
      </BeamButton>
    </ShopifyContentWrapper>
  )
}
